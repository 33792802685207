<template>
  <v-card class="upload-card fill-height" flat>
    <v-card-title>
      <h3>Imports</h3>
      <v-spacer />
      <company-selector />
    </v-card-title>
    <v-card-text>
      <v-select
        :items="imports"
        box
        item-text="name"
        label="Import"
        return-object
        v-model="currentImport"
      />

      <yearpicker label="Jahr" v-model="year" />

      <v-text-field
        :value="$store.getters.currentCompany.name"
        box
        disabled
        label="Firma"
        v-if="currentImport.needsCompany"
      />

      <v-checkbox
        label="Bestandsdaten überschreiben"
        v-if="currentImport.canOverwrite"
        v-model="overwriteExistingData"
      />
    </v-card-text>
    <template v-if="selectedFile">
      <v-list>
        <v-list-tile>
          <v-list-tile-avatar>
            <v-icon>insert_drive_file</v-icon>
          </v-list-tile-avatar>

          <v-list-tile-content>
            <v-list-tile-title>{{ selectedFile.filename }}</v-list-tile-title>
            <v-list-tile-sub-title>{{
              selectedFile.total | niceFileSize
            }}</v-list-tile-sub-title>
          </v-list-tile-content>

          <v-list-tile-action>
            <v-btn icon ripple @click="selectedFile = null">
              <v-icon color="grey lighten-1">close</v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>

      <v-card-text>
        <v-layout>
          <v-spacer />
          <v-btn :loading="isUploading" color="primary" @click="submit">
            Import starten
          </v-btn>
        </v-layout>
      </v-card-text>
    </template>

    <v-layout
      v-show="!selectedFile"
      align-center
      justify-center
      class="pa-4 ma-3 upload-wrap"
    >
      <vue-dropzone
        :options="dropzoneOptions"
        :useCustomSlot="true"
        @vdropzone-complete="onComplete"
        @vdropzone-error="onError"
        @vdropzone-file-added="fileAdded"
        @vdropzone-sending="onSend"
        id="dropzone"
        ref="dropzone"
      >
        <div style="height: 100%" class="dropzone-custom-content">
          <v-layout justify-center align-center column>
            <v-progress-circular
              v-if="isUploading"
              class="mb-3"
              :size="70"
              :width="7"
              color="accent"
              indeterminate
            />
            <template v-else>
              <v-icon color="grey lighten-2" class="mx-2" size="80"
                >add_circle_outline</v-icon
              >
              <div class="grey--text lighten-2 subtitle">
                In dieses Feld ziehen oder hier klicken...
              </div>
            </template>
          </v-layout>
        </div>
      </vue-dropzone>
    </v-layout>

    <v-card-title>
      <h3>Aufbau .xls/.xlsx Datei</h3>
    </v-card-title>

    <v-data-table
      :hide-actions="true"
      v-bind="{
        headers: currentImport.headers,
        items: currentImport.items,
      }"
    >
      <tr slot="items" slot-scope="props">
        <td>{{ props.item.t1 }}</td>
        <td>{{ props.item.t2 }}</td>
        <td>{{ props.item.t3 }}</td>
      </tr>
      <template slot="pageText" slot-scope="props">
        Einträge {{ props.pageStart }} bis {{ props.pageStop }} von insgesamt
        {{ props.itemsLength }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Yearpicker from '@/components/dates/Yearpicker'
import vue2Dropzone from 'vue2-dropzone'
import { getAuthHeader } from '@/api/keycloakAuth'
import { niceFileSize } from '@/utils/niceSize'
import CompanySelector from '@/components/CompanySelector'

export default {
  name: 'Imports',
  components: {
    CompanySelector,
    Yearpicker,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      imports: [
        {
          name: 'Berechnete Gewerbesteuerdaten',
          url: (companyId) => `/api/companies/${companyId}/import/businessTax`,
          needsCompany: true,
          canOverwrite: false,
          headers: [
            { text: 'AGS', value: 't1', sortable: false },
            { text: 'GewSt', value: 't2', sortable: false },
            { text: '', value: 't3', sortable: false },
          ],
          items: [
            { t1: '15084005', t2: ' 354.50 €', t3: '' },
            { t1: '14523010', t2: ' 534.05 €', t3: '' },
          ],
        },
        {
          name: 'Hebesätze',
          url: () => `/api/import/municipalityRate`,
          needsCompany: false,
          canOverwrite: true,
          headers: [
            { text: 'AGS', value: 't1', sortable: false },
            { text: 'Gemeinde', value: 't2', sortable: false },
            { text: 'Hebesatz', value: 't3', sortable: false },
          ],
          items: [
            { t1: '15082010', t2: 'Altjeßnitz', t3: 330 },
            {
              t1: '15082165',
              t2: 'Jeßnitz (Anhalt), Stadt',
              t3: 320,
            },
            { t1: '15082215', t2: 'Marke', t3: 340 },
          ],
        },
        {
          name: 'IHK Zugehörigkeit',
          url: () => `/api/import/ihkName`,
          needsCompany: false,
          canOverwrite: true,
          headers: [
            { text: 'AGS', value: 't1', sortable: false },
            { text: 'Gemeinde', value: 't2', sortable: false },
            { text: 'IHK', value: 't3', sortable: false },
          ],
          items: [
            {
              t1: '15082010',
              t2: 'Altjeßnitz',
              t3: 'IHK Halle Dessau',
            },
            {
              t1: '15082165',
              t2: 'Jeßnitz (Anhalt), Stadt',
              t3: 'IHK Halle Dessau',
            },
            { t1: '15082215', t2: 'Marke', t3: 'IHK Halle Dessau' },
          ],
        },
      ],
      currentImport: null,
      year: null,
      isUploading: false,
      overwriteExistingData: false,
      selectedFile: null,
    }
  },
  computed: {
    dropzoneOptions() {
      const companyId = this.$store.getters.currentCompany.id
      const url = this.currentImport.url(companyId)
      return {
        url,
        autoProcessQueue: false,
        parallelUploads: 1,
        thumbnailWidth: 0,
        maxFiles: 1,
        timeout: -1,
        acceptedFiles: [
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          '.xls',
          '.xlsx',
        ].join(','),
      }
    },
  },
  methods: {
    onError(file) {
      if (file.status === 'error') {
        const response = JSON.parse(file.xhr.response)
        let text = 'Fehler beim Import aufgetreten'
        if (response.errors) {
          const error = response.errors[0]
          text = `${error}`
        }
        this.$root.setSnackbar('error', text)
      }
    },
    fileAdded(file) {
      if (
        file.type === 'application/vnd.ms-excel' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        this.selectedFile = file.upload
      } else {
        this.$root.setSnackbar(
          'error',
          'Bitte nur Excel Dateien (xls, xlsx) hochladen.'
        )
        this.$refs.dropzone.removeFile(file)
      }
    },
    submit() {
      const dropzone = this.$refs.dropzone
      dropzone.setOption('url', this.dropzoneOptions.url)
      dropzone.setOption('headers', getAuthHeader())
      dropzone.processQueue()
    },

    onSend(file, xhr, formData) {
      this.isUploading = true
      formData.append('company_id', this.$store.getters.currentCompany.id)
      formData.append('year', this.year)
      formData.append(
        'overwrite_existing_data',
        `${this.overwriteExistingData}`
      )
    },

    onComplete(file) {
      if (file.status === 'success') {
        let text = ''

        if (this.currentImport.url === 'businessTax') {
          const [failed, processed] = JSON.parse(file.xhr.response)
          text = `${processed} Einträge wurden aktualisiert`
          if (failed.length) {
            text = `${text}, ${failed.length} wurden nicht gefunden.`
          }
        } else {
          text = 'Erfolgreich importiert'
        }
        this.$root.setSnackbar('success', text)
      } else {
        this.$root.setSnackbar(
          'error',
          'Es ist ein Fehler beim Upload aufgetreten'
        )
      }
      this.$refs.dropzone.removeAllFiles(true)
      this.selectedFile = null
      this.isUploading = false
    },
  },
  created() {
    this.currentImport = this.imports[0]
    this.year = new Date().getFullYear()
  },
  filters: {
    niceFileSize,
  },
}
</script>

<style>
.upload-wrap {
  border: 1px dashed #c8c8c8;
}

.dz-success-mark,
.dz-error-mark {
  display: none;
}
</style>
